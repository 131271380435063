@use "sass:map";

// More fonts icon in node_module/fontawesome
$icons: (
  arrow-up: "\f062",
  arrow-down: "\f063",
  angle-left: "\f104",
  angle-down: "\f107",
  arrow-right: "\f061",
  ban: "\f05e",
  bars: "\f0c9",
  bell: "\f0f3",
  bold: "\f032",
  chart-pie: "\f200",
  check: "\f00c",
  check-circle: "\f058",
  check-to-slot: "\f772",
  chevron-left: "\f053",
  chevron-right: "\f054",
  code: "\f121",
  cog: "\f013",
  coins: "\f51e",
  copy: "\F0C5",
  columns: "\f0db",
  comment: "\f075",
  comment-dollar: "\f651",
  comments: "\f086",
  dollar-sign: "\f155",
  download: "\f019",
  edit: "\f044",
  ellipsis-v: "\f142",
  ellipsis-h: "\f141",
  envelope: "\f0e0",
  envelope-open-text: "\f658",
  exclamation-triangle: "\f071",
  external-link-alt: "\f35d",
  eye: "\f06e",
  eye-slash: "\f070",
  globe: "\f0ac",
  grin-beam: "\f583",
  heart: "\f004",
  info: "\f129",
  info-circle: "\f05a",
  italic: "\f033",
  link: "\f0c1",
  lock: "\f023",
  unlock-alt: "\f13e",
  minus-circle: "\f056",
  paper-plane: "\f1d8",
  pencil-alt: "\f303",
  plus: "\f067",
  poll: "\f681",
  question: "\f128",
  question-circle: "\f059",
  reply: "\f3e5",
  share-alt: "\f1e0",
  search: "\f002",
  smile-beam: "\f5b8",
  sort-down: "\f0dd",
  star: "\f005",
  strikethrough: "\f0cc",
  tag: "\f02b",
  times: "\f00d",
  times-circle: "\f057",
  thumbtack: "\f08D",
  undo: "\f0e2",
  user: "\f007",
  user-plus: "\f234",
  user-minus: "\f503",
  vote-yea: "\f772",
  wifi: "\f1eb",
  hashtag: "\f292",
  trash-alt: "\f2ed",
  magnifying-minus: "\f010",
  magnifying-plus: "\f00e",
  paper-clip: "\f0c6",
  file: "\f15c",
  newspaper: "\f1ea"
);

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Icons/fa-solid-900.eot");
  src:
    url("../fonts/Icons/fa-solid-900.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Icons/fa-solid-900.woff2") format("woff2"),
    url("../fonts/Icons/fa-solid-900.woff") format("woff"),
    url("../fonts/Icons/fa-solid-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Icons/fa-regular-400.eot");
  src:
    url("../fonts/Icons/fa-regular-400.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Icons/fa-regular-400.woff2") format("woff2"),
    url("../fonts/Icons/fa-regular-400.woff") format("woff"),
    url("../fonts/Icons/fa-regular-400.ttf") format("truetype");
}

[class^="icon-"] {
  display: inline-block;
  font-style: normal;
  font-variant: normal;

  &::before {
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  &.is-regular::before {
    font-weight: 400;
  }

  &.is-prefix {
    margin-right: 0.5rem;
  }

  &.is-suffix {
    margin-left: 0.5rem;
  }
}

@each $name, $icon in $icons {
  .icon-#{$name}::before {
    content: $icon;
  }
}

.icon-eye::before {
  font-weight: 400; /* thinner version */
}

.icon-round {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  flex-shrink: 0;
  border-radius: 50%;
  text-align: center;
  line-height: 2rem;
}

@mixin icon($name) {
  content: map.get($icons, $name);
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
}
