// Breakpoints
// // Same as JS breakpoints at breakpoints.js
// TODO: Redefine the breakpoints with @mmbotelho in a next task.
$gap: 1rem;
$tablet: 769px;
$desktop: 1200px;
$payment-table-desktop-bp: 1290px;

@mixin fa($size, $dimensions) {
  display: inline-block;
  font-size: $size;
  height: $dimensions;
  line-height: $dimensions;
  text-align: center;
  vertical-align: top;
  width: $dimensions;
}

@mixin overflow-touch {
  -webkit-overflow-scrolling: touch;
}

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin payment-table-desktop {
  @media screen and (min-width: $payment-table-desktop-bp) {
    @content;
  }
}

@mixin if-forced-color-mode {
  @media (forced-colors: active) {
    @content;
  }
}

%unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

%reset-button {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  min-height: auto;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

%floating-panel {
  @include phone {
    top: auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 1rem 1rem 0 0;
  }

  @include tablet {
    position: fixed;
    top: 4.25rem;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  @include desktop {
    top: 2.3rem;
    position: absolute;
    right: auto;
    transform: none;
  }
}

%floating-panel-item {
  height: 2.31rem;

  @include touch {
    height: 3.43rem;
  }
}
