@use "variables" as *;

$bannerGeneralHeight: 2.3rem;

.l-no-navigation {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-areas:
    "l-banner"
    "l-page";
  grid-template-rows: min-content auto;

  .l-page {
    padding: 1rem;

    @include tablet {
      padding: 1.5rem;
    }
  }
}

.l-with-navigation {
  display: grid;
  overflow: hidden;
  height: 100%;
  grid-template-areas:
    "l-banner l-banner"
    "l-navigation l-page";
  grid-template-rows: min-content auto;

  @include tablet {
    grid-template-columns: auto 1fr;
    grid-template-rows: min-content 1fr;

    .l-banner ~ .l-navigation { // this is a fix for https://github.com/okTurtles/group-income/issues/2174
      height: 100%;
    }
  }
}

.l-banner {
  grid-area: l-banner;
  position: sticky;
  height: $bannerGeneralHeight;
  width: 100%;
  text-align: center;
  font-weight: 600;
  padding: 0.5rem;
  z-index: $zindex-banner;

  & ~ .l-navigation {
    height: calc(100% - #{$bannerGeneralHeight});

    @include touch {
      margin-top: $bannerGeneralHeight;
    }
  }

  & ~ .l-page .p-sidebar {
    height: calc(100% - #{$bannerGeneralHeight});
  }

  & ~ .l-modal .modal {
    top: $bannerGeneralHeight;
  }

  @include touch {
    & ~ .l-modal {
      .c-modal {
        margin-top: $bannerGeneralHeight;
      }

      .c-modal-close,
      .has-background .c-modal-close {
        top: 3rem;
      }
    }
  }
}

.l-navigation {
  grid-area: l-navigation;
  height: 100%;

  @include until($desktop) {
    position: absolute;
    z-index: $zindex-sidebar;
    transform: translateX(-100%);
    transition: transform $transitionSpeed;

    &.is-active {
      transform: translateX(0);
    }
  }
}

.l-page {
  position: relative;
  grid-area: l-page;
  overflow-y: auto;
  overflow-x: hidden; /* so sidebars can be hidden correctly */
}

.l-modal {
  position: absolute;
}
