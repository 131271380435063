// z-index constants
// Why: https://css-tricks.com/handling-z-index/
$zindex-banner: 70;
$zindex-mask: 60;
$zindex-tooltip: 50;
$zindex-modal: 40;
$zindex-sidebar: 40;
$zindex-prompt: 45; // some page content presented as 'position: fixed' has z-index larger than $zindex-modal (e.g. GroupWelcome.vue)
$zindex-header: 30;

// Used for typography
$size_0: 4rem;     // 64px
$size_1: 2rem;     // 32px
$size_2: 1.5rem;   // 24px
$size_3: 1.125rem; // 18px
$size_4: 0.875rem; // 14px
$size_5: 0.75rem;  // 12px

// Alias for fonts
$size_small: $size_5;
$size_normal: $size_4;
$size_medium: $size_3;
$size_large: $size_2;
$size_extra-large: $size_1;

$radius: 3px;
$radius-large: 5px;

$transitionSpeed: 300ms;

$rightSideWidth: 16.5rem;

// IMPORTANT!
// This file, and any files included below, cannot contain any CSS
// classes, because these files are often included in the <style>
// tag of .vue components, and so those classes would end up being
// duplicated across all the injected component CSS

@forward "colors";
@forward "mixins";
