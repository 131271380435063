@use "../variables" as *;

.custom-markdown-content {
  // style declarations for custom markdown html output content
  white-space: initial;
  -webkit-touch-callout: none;

  &.c-replying {
    border-left: 2px;
    border-color: #dbdbdb; // var(--text_1);
    border-style: none none none solid;
    font-size: 0.75rem;
    color: var(--text_1);
    font-style: italic;
    padding-left: 0.25rem;
    white-space: pre-line;

    &:hover {
      cursor: pointer;
      color: var(--text_2);
      border-color: var(--text_1); // var(--text_2);
    }

    .c-member-mention,
    .c-channel-mention {
      background-color: transparent;
    }
  }

  code {
    display: inline-block;
    border-radius: $radius;
    padding: 0.25rem;
    line-height: 1;
    font-size: $size_5;
    background-color: $general_1;
    color: $warning_0;
    margin-left: 1px;
    margin-right: 1px;
  }

  pre > code {
    display: block;
    width: 100%;
    padding: 0.5rem;
    background-color: $general_1;
    line-height: 1.4;
    margin: 0;
    max-width: 100%;
    overflow-x: auto;
  }

  * + pre,
  * + blockquote {
    margin-top: 0.25rem;
  }

  em {
    margin-left: 1px;
    margin-right: 1px;
  }

  ul,
  ol {
    margin: 0.75rem 0;
  }

  ul > li {
    position: relative;
    display: block;
    padding: 0 0 0.2rem 1.125rem;

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0.2rem;
      top: 0.6rem;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: $text_0;
    }
  }

  ol > li {
    position: relative;
    padding-bottom: 0.2rem;
    margin-left: 1.125rem;

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

  li {
    // style correction for a nested sub-list within <li></li>.
    > ul,
    > ol {
      margin-bottom: 0;
    }

    > ul > li::before {
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid $text_0;
      top: 0.6rem;
      width: 0.4rem;
      height: 0.4rem;
    }
  }

  blockquote {
    position: relative;
    padding-left: 1rem;
    white-space: pre-line;
    margin: 0.25rem 0;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 4px;
      top: 0;
      bottom: 0;
      border-radius: 8px;
      background-color: $general_0;
    }

    p:has(+ p, + pre),
    pre:has(+ p, + pre) {
      margin-bottom: 1rem;
    }
  }

  .link {
    border-bottom-color: currentColor;
  }

  .c-edited {
    margin-left: 0.2rem;
    font-size: 0.7rem;
    color: var(--text_1);
  }

  .chat-emoji {
    font-size: 1.2em;
  }
}
