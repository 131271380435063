@use "variables" as *;
@use "sass:list";

@font-face {
  src: url(../fonts/Lato/Lato-Bold.ttf);
  font-family: "Lato";
  font-weight: 700;
}

@font-face {
  src: url(../fonts/Lato/Lato-Regular.ttf);
  font-family: "Lato";
  font-weight: 400;
}

@font-face {
  src: url(../fonts/Poppins/Poppins-Bold.ttf);
  font-family: "Poppins";
  font-weight: 700;
}

@font-face {
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
  font-family: "Poppins";
  font-weight: 400;
}

html {
  background-color: white;
  font-size: var(--text-size);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 19rem;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

body {
  font-family: "Lato", "Helvetica Neue", "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: $size_4; // 14px
  line-height: 1.5; // 21/14
  color: $text_0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0; // reset margins
}

// From _variables.scss
// $size_1 = 2rem     = 32px
// $size_2 = 1.5rem   = 24px = $size_large
// $size_3 = 1.125rem = 18px = $size_medium
// $size_4 = 0.875rem = 14px = $size_normal
// $size_5 =  0.75rem = 12px = $size_small

$titles: $size_1, $size_2, $size_3, $size_4;
$lineHeight: 3, 2.25, 1.6875, 1.3125, 1;
// From line height: 48px, 36px, 27px, 21px, 16px

@each $size in $titles {
  $i: list.index($titles, $size);

  .is-title-#{$i} {
    font-family: "Poppins";
    font-weight: bold;
    font-size: $size;
    line-height: #{list.nth($lineHeight, $i)}rem;
  }
}

@include until($tablet) {
  .is-title-1 {
    font-size: $size_2; // 24px
    line-height: 2.125rem; // 34px
  }

  .is-title-2 {
    font-size: 1.25rem; // 20px
    line-height: 1.75rem; // 28px
  }
}

@include phone {
  .is-title-1 {
    font-size: 1.25rem; // 20px
    line-height: 2.125rem; // 34px
  }

  .is-title-2 {
    font-size: $size_3; // 18px
    line-height: 1.5625rem; // 25px
  }
}

.is-subtitle {
  font-family: "Poppins";
  text-transform: uppercase;
  letter-spacing: 0.1px;
  font-weight: normal;
  font-size: $size_5;
  line-height: 1rem;
  color: $text_1;
}

.label {
  font-weight: 600;
}

.has-text-big {
  font-size: 1rem;
}

.help,
.has-text-small,
small {
  font-size: $size_5;
}

.has-text-normal {
  font-family: "Lato";
  font-weight: 400;
}

.has-text-bold {
  font-weight: 600;
}

.has-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.has-text-0 {
  color: $text_0;
}

.help,
.has-text-1 {
  color: $text_1;
}

@each $name in $colors {
  .has-text-#{$name} {
    color: var(--#{$name}_0);
  }

  .has-background-#{$name} {
    background-color: var(--#{$name}_2);
  }

  .has-background-#{$name}-solid {
    background-color: var(--#{$name}_0);
  }
}

.has-background-blank {
  background-color: $background;
}

.has-text-white {
  color: $white;
}
