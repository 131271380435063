@use "../variables" as *;

// Todo move into it"s own component when once created
.steps {
  display: none;
  text-align: center;
  justify-content: space-between;
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 0.25rem;
  margin-left: -0.25rem;

  @include tablet() {
    display: flex;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    z-index: -3;
    height: 0.125rem;
    width: 100%;
    background-color: $primary_0;
    top: 50%;
    margin-top: -1px;
  }

  &::after {
    background-color: $background_0;
    z-index: 3;
    width: 0.25rem;
    right: 0;
  }

  .step {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    font-size: $size_5;
    transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    min-height: 0.8rem;
    padding: 0;

    &::before {
      content: "";
      border-radius: 50%;
      background-color: $general_2;
      position: absolute;
      z-index: -1;
      border: 1px solid $primary_0;
      width: 100%;
      height: 100%;
      left: 0;
      transform-origin: 50%;
      transform: scale(0);
      transition: transform 300ms ease-out;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -2;
      left: 0;
      top: 50%;
      width: 0;
      height: 2px;
      margin-top: -1px;
      background-color: $general_0;
    }
  }

  .step:not(.next):hover,
  .active {
    background-color: $primary_0;
    font-weight: bold;

    &::before {
      transform: scale(1.3334);
    }

    &::after {
      width: 100vw;
    }

    &:last-child::after {
      z-index: 4;
      width: 3px;
      left: -3px;
      background-color: $white;
    }

    &:last-child::before {
      z-index: 5;
      background-color: transparent;
      box-shadow: inset 0 0 0 1.8px $general_2;
    }
  }

  .next {
    background-color: $white;
    color: $text_1;
    cursor: not-allowed;
    pointer-events: none;
    border: 1px solid $general_0;
  }
}

.mobile-steps {
  margin: 1.5rem 0 -1rem 0;

  @include tablet() {
    display: none;
  }
}

.steps-title {
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  @include phone {
    font-size: 1.25rem; // 20px
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
  }
}
