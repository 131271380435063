@use "variables" as *;

@mixin reduce-motion {
  *:not(.force-motion),
  *:not(.force-motion)::after,
  *:not(.force-motion)::before {
    transition: none !important;
    animation: none !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* message transition */
.slidedown-enter-active,
.slidedown-leave-active {
  transition: max-height 0.3s ease;

  /* Todo: move that to dynmaic js, so it's mnore generic */
  max-height: 10rem;
  overflow: hidden;
}

.slidedown-enter,
.slidedown-leave-to {
  max-height: 0;
}

.replace-list-enter-active,
.replace-list-leave-active {
  transition: max-height 0.15s ease, opacity 0.15s ease;
  opacity: 1;

  /* Todo: move that to dynamic js, so it's mnore generic */
  max-height: 10rem;
  overflow: hidden;
}

.replace-list-enter-active {
  transition: max-height 0.3s ease, opacity 0.3s ease 0.2s;
}

.replace-list-enter,
.replace-list-leave-to {
  max-height: 0;
  opacity: 0;
}

/* slide transition */
.slide-next-enter-active,
.slide-next-leave-active,
.slide-prev-enter-active,
.slide-prev-leave-active {
  @include tablet {
    transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s;
  }
}

.slide-next-leave-active,
.slide-prev-leave-active {
  @include tablet {
    transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
}

.slide-prev-leave-to,
.slide-next-enter {
  @include tablet {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.slide-prev-enter,
.slide-next-leave-to {
  @include tablet {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.slide-fade-enter-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.slide-fade-leave-active {
  transition: opacity 0.3s cubic-bezier(1, 0.5, 0.8, 1), transform 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.3s;
}

.slide-left-enter,
.slide-left-leave-to {
  transform: translate3d(100%, 0, 0);

  @include desktop {
    transform: none;
  }
}

@include desktop {
  .slide-left-enter {
    animation: zoom 0.3s;
  }

  .slide-left-leave-to {
    animation: zoom 0.3s reverse;
  }
}

.zoom-enter-active {
  animation: enterFromBottom 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);

  @include tablet {
    animation: zoom 0.3s;
  }
}

.zoom-leave-active {
  animation: enterFromBottom 500ms both cubic-bezier(0.165, 0.84, 0.44, 1) reverse;

  @include tablet {
    animation: zoom 0.3s reverse;
  }
}

@keyframes blink-animation {
  to {
    opacity: 0;
  }
}

@keyframes enterFromBottom {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@media (prefers-reduced-motion: reduce) {
  @include reduce-motion;
}

.js-reducedMotion {
  @include reduce-motion;
}

@keyframes focused {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: $primary_2;
  }

  100% {
    background-color: transparent;
  }
}

@keyframes disappeared {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: $danger_2;
  }
}
