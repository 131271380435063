@use "../variables" as *;

.link {
  display: inline; // so border breaks correctly on multiple lines.
  color: $primary_0;
  border-bottom: 1px solid $primary_1;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  transition: border-color 0.2s, color ease-in 0.2s;
  padding-left: 0;
  padding-right: 0;
  border-width: 0 0 1px 0;
  line-height: 1.4; // better underline visually

  &.has-icon {
    &::before {
      content: "";
      position: absolute;
      width: 1.3rem;
      bottom: -1px;
      left: 0;
      border-bottom: 2px solid $background;
      display: inline-block;
    }

    [class^="icon-"] {
      margin-right: 0.5rem;
    }
  }

  strong {
    color: currentColor;
  }

  &:hover,
  &:focus {
    color: $text_0;
    border-bottom-color: $text_0;
  }

  &.no-border {
    border-bottom: none;
  }
}

.sublink {
  color: $text_1;
  font-weight: normal;
  cursor: pointer;
  text-decoration: underline;
  position: relative;
  transition: color ease-in 0.2s;
  padding-left: 0;
  padding-right: 0;

  &:hover,
  &:focus {
    color: $text_0;
  }
}
