@use "../variables" as *;

.loading-box {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 0.625rem; // 10px
  margin-bottom: 1rem;
  min-height: 12.5rem;
  background: linear-gradient(to right, var(--general_1) 8%, var(--general_2) 18%, var(--general_1) 100%);
  background-size: 100vw 10vw;
  animation: 6s linear forwards infinite placeload;

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      animation-delay: 200ms * $i;
    }
  }
}

@keyframes placeload {
  0% { background-position: -100% 0; }
  25% { background-position: 100% 0; }
  50% { background-position: 100% 0; }
  100% { background-position: 100% 0; }
}

.main-loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  background-color: #ededed;

  @media (prefers-color-scheme: dark) {
    background-color: #2e3032;
  }

  img {
    display: inline-block;
    width: 2.4rem;
    height: auto;
    transform-origin: center center;
    animation: logo-rotation 5s linear infinite;

    @include from ($tablet) {
      width: 3rem;
    }
  }

  @keyframes logo-rotation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
